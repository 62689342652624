import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container, Row, Col, Input } from "reactstrap";

const Blog = ({ data }) => {
    const { edges: posts, categories } = data.allMdx;

    return (
        <Layout>
            <SEO title="Blog" />

            <Container style={{ paddingTop: 200 }} className="pb-5 mb-5">
                <Row>
                    <Col>
                        {
                            posts.map(({ node }) => {
                                const { title, author, date, icon } = node.frontmatter;

                                return (
                                    <Row key={node.id} className="pb-5">
                                        <Col md={4}>
                                            <img src={icon?.publicURL} className="w-100" />
                                        </Col>
                                        <Col className="shadow-hover pl-3 pr-3" tag="article">
                                            <header>
                                                <Link to={node.fields.slug}>
                                                    <h1 className="h3 mt-0">{title}</h1>
                                                </Link>
                                                <div className="d-flex justify-content-between pb-3">
                                                    <span>
                                                        {new Date(date).toLocaleDateString(undefined, { weekday: "long", year: "numeric", month: "long", day: "numeric" })}
                                                    </span>
                                                    <span className="text-muted">
                                                        {author}
                                                    </span>
                                                </div>
                                            </header>
                                            <p>{node.excerpt}</p>
                                            <Link to={node.fields.slug}>Continue reading</Link>
                                        </Col>
                                    </Row>
                                );
                            })
                        }
                    </Col>
                    {/* <Col md={4}>
                        <Input />

                        <div className="d-flex flex-column align-items-start">
                            {
                                categories.map(x =>
                                    <div key={x} className="badge badge-pill badge-primary">
                                        {x}
                                    </div>
                                )
                            }
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </Layout>
    );
}

export const query = graphql`
query BlogPostsQuery {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
        edges {
            node {
                id
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    title
                    author
                    date
                    icon {
                        publicURL
                    }
                }
            }
        }
        categories: distinct(field: frontmatter___category)
    }
}
`

export default Blog;
